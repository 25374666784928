import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { LAYOUT_MENU_ITEMS } from './layout-menu.constants';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { RequirePermissionDirective } from 'src/app/shared/directives/require-permission/require-permission.directive';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, startWith } from 'rxjs';

@Component({
  selector: 'app-layout-menu',
  standalone: true,
  imports: [CommonModule, MaterialModule, RequirePermissionDirective, RouterModule],
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutMenuComponent {
  protected readonly menuItems = [...LAYOUT_MENU_ITEMS];
  protected readonly url = toSignal(
    this.router.events.pipe(
      takeUntilDestroyed(),
      filter((event) => event instanceof NavigationEnd),
      startWith(true),
      map(() => window.location.href)
    )
  );

  constructor(protected readonly router: Router) {}
}
