import { Injectable } from '@angular/core';
import { LoginRequest, PasswordRecoverDTO, SharedLogin } from '../models/authentication.models';
import { AuthenticationApiService } from './authentication-api.service';
import { catchError, tap } from 'rxjs';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthUserService } from 'src/app/core/services/auth-user/auth-user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationDomainService {
  constructor(
    private readonly authenticationApiService: AuthenticationApiService,
    private readonly authUserService: AuthUserService,
    private readonly router: Router,
    private readonly toastService: ToastService
  ) {}

  public logIn = (data: LoginRequest) => {
    return this.authenticationApiService.logIn(data).pipe(
      tap((user) => this.authUserService.set(user)),
      catchError((error: HttpErrorResponse) => {
        const { message } = error.error;
        this.toastService.error(message);
        throw error;
      })
    );
  };

  public logOut = () => {
    this.authUserService.delete();
    this.router.navigate(['/authentication', 'log-in']);
  };

  public requestRecovery = (email: string) => {
    return this.authenticationApiService.requestRecovery(email);
  };

  public recoverPassword = (recover: PasswordRecoverDTO) => {
    return this.authenticationApiService.recoverPassword(recover);
  };

  public shareAuth = (params: SharedLogin) =>{
    return this.authenticationApiService.shareAuth(params).pipe(
      tap((user)=>this.authUserService.set(user))
    )
  };
}
