import { Injectable } from '@angular/core';
import { PaginationValues } from 'src/app/shared/utils/pagination/pagination.models';
import { UsersApiService } from './users-api.service';
import {
  UserPasswordUpdateDTO,
  UserProfileUpdateDTO,
  UserTabCreateDTO,
  UserUpsertDTO,
} from '../models/users.models';
import { AnalyticMetricsFilterDTO } from '../../analytics/models/analytics.models';

@Injectable({
  providedIn: 'root',
})
export class UsersDomainService {
  constructor(private readonly usersApiService: UsersApiService) {}

  public getPaginated = (pagination: PaginationValues) => {
    return this.usersApiService.getPaginated(pagination);
  };

  public getOne = (id: number) => {
    return this.usersApiService.getOne(id);
  };

  public getProfile = () => {
    return this.usersApiService.getProfile();
  };

  public getTabs = () => {
    return this.usersApiService.getTabs();
  };

  public getTab = (id: number) => {
    return this.usersApiService.getTab(id);
  };

  public createOne = (user: UserUpsertDTO) => {
    return this.usersApiService.createOne(user);
  };

  public updateOne = (id: number, user: UserUpsertDTO) => {
    return this.usersApiService.updateOne(id, user);
  };

  public deleteOne = (id: number) => {
    return this.usersApiService.deleteOne(id);
  };

  public updateProfile = (profile: UserProfileUpdateDTO) => {
    return this.usersApiService.updateProfile(profile);
  };

  public updatePassword = (data: UserPasswordUpdateDTO) => {
    return this.usersApiService.updatePassword(data);
  };

  public createTab = (data: UserTabCreateDTO) => {
    return this.usersApiService.createTab(data);
  };
}
