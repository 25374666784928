<button class="menu-trigger" (click)="toggleMenu.emit()">
  <mat-icon>menu</mat-icon>
  <span>Menú</span>
</button>
<a [routerLink]="['/dashboard']">
  <img class="logo" src="./assets/images/logo/jcyl-ctd-texto.png" alt="Junta de Castilla y León" />
</a>
<ng-container *ngIf="user$ | async as user; else profileIsLoading">
  <button class="profile" [matMenuTriggerFor]="menu">
    <img [src]="user.avatar" alt="Usuario" />
  </button>
  <mat-menu #menu="matMenu">
    <div class="menu-header">
      <p class="options">Menu de usuario</p>
      <p>{{ user.firstname }} {{ user.lastname }}</p>
      <p>{{ user.email }}</p>
    </div>
    <a mat-menu-item [routerLink]="['/dashboard', 'profile']">
      <mat-icon>account_circle</mat-icon>
      <span>Mi perfil</span>
    </a>
    <button mat-menu-item (click)="logOut()">
      <mat-icon>logout</mat-icon>
      <span>Cerrar sesión</span>
    </button>
  </mat-menu>
</ng-container>
<ng-template #profileIsLoading>
  <button class="profile">
    <img src="./assets/images/logo/jcyl.png" alt="Usuario" />
  </button>
</ng-template>
