import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import { MatDrawerMode } from '@angular/material/sidenav';
import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { LayoutMenuComponent } from './layout-menu/layout-menu.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';
import { ResizeService } from '../services/resize/resize.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    LayoutFooterComponent,
    LayoutHeaderComponent,
    LayoutMenuComponent,
    RouterModule,
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, OnDestroy {
  protected readonly breakpoint: number = 1280;
  protected readonly alive$ = new Subject<void>();
  protected readonly open = signal(false);
  protected readonly mode = signal<MatDrawerMode>('side');
  protected readonly today = new Date();

  constructor(private readonly resizeService: ResizeService, private readonly router: Router) {}

  ngOnInit(): void {
    this.watchNavigationChanges();
    this.watchResizeChanges();
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }

  public toggleMenu = () => {
    const value = this.open();
    this.open.set(!value);
  };

  public closeMenu = () => {
    this.open.set(false);
  };

  public refreshResize = () => {
    if (this.mode() !== 'over') {
      this.resizeService.refresh();
    }
  };

  public watchNavigationChanges = () => {
    this.router.events
      .pipe(
        takeUntil(this.alive$),
        filter((event) => event instanceof NavigationEnd),
        filter(() => window.innerWidth < this.breakpoint)
      )
      .subscribe(() => this.open.set(false));
  };

  public watchResizeChanges = () => {
    this.resizeService.resize$
      .pipe(
        takeUntil(this.alive$),
        map((width) => (width < this.breakpoint ? 'over' : 'side')),
        distinctUntilChanged()
      )
      .subscribe((mode) => {
        this.open.set(mode === 'side' ? true : false);
        this.mode.set(mode);
      });
  };
}
