export enum MessageType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  Loading = 'loading',
}

export const MESSAGE_ICONS: { [key in MessageType]?: string } = {
  [MessageType.Success]: 'check_circle_outline',
  [MessageType.Warning]: 'error_outline',
  [MessageType.Error]: 'gpp_bad',
  [MessageType.Info]: 'help',
};
