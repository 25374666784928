import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  blur = () => {
    const element = document.activeElement;

    if (element && element instanceof HTMLElement) {
      element.blur();
    }
  };
}
