<form [formGroup]="form" (submit)="submit()">
  <article>
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input
        matInput
        type="text"
        id="firstname"
        name="firstname"
        formControlName="firstname"
        autocomplete="off"
      />
      <mat-icon matSuffix>person</mat-icon>
      <mat-hint>Obligatorio</mat-hint>
      <mat-error appFormError control="firstname"></mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Apellidos</mat-label>
      <input
        matInput
        type="text"
        id="lastname"
        name="lastname"
        formControlName="lastname"
        autocomplete="off"
      />
      <mat-icon matSuffix>group</mat-icon>
      <mat-hint>Obligatorio</mat-hint>
      <mat-error appFormError control="lastname"></mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>E-mail</mat-label>
      <input
        matInput
        type="text"
        id="email"
        name="email"
        formControlName="email"
        autocomplete="off"
      />
      <mat-icon matSuffix>email</mat-icon>
      <mat-hint>No editable</mat-hint>
      <mat-error appFormError control="email"></mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Teléfono</mat-label>
      <input
        matInput
        type="text"
        id="phone"
        name="phone"
        formControlName="phone"
        autocomplete="off"
      />
      <mat-icon matSuffix>phone</mat-icon>
      <mat-error appFormError control="phone"></mat-error>
    </mat-form-field>

    <div class="message" @dynamicHeightRender *ngIf="notification()">
      <app-message type="info">
        Debes hacer algún cambio en el formulario para poder actualizar tu perfil de usuario
      </app-message>
    </div>
  </article>

  <aside>
    <app-avatar (upload)="updateAvatar($event)" [avatar]="avatar()"></app-avatar>
  </aside>

  <footer>
    <mat-divider></mat-divider>
    <button
      mat-raised-button
      color="primary"
      [disabled]="form.invalid || form.pristine || loading()"
    >
      Aplicar cambios
    </button>
  </footer>
</form>
