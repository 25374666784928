import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthUserService } from 'src/app/core/services/auth-user/auth-user.service';
import { Permission } from 'src/app/modules/roles/constants/roles.constants';

@Directive({
  selector: '[appRequirePermission]',
  standalone: true,
})
export class RequirePermissionDirective implements OnInit {
  @Input({ alias: 'appRequirePermission', required: true })
  permission!: Permission;

  constructor(
    private readonly authUserService: AuthUserService,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    const user = this.authUserService.get();

    if (!user) {
      return this.viewContainerRef.clear();
    }
    if (!user.user.permissions.includes(this.permission)) {
      return this.viewContainerRef.clear();
    }
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
