import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import * as R from 'remeda';

export class CustomValidators {
  static password: ValidatorFn = (control: AbstractControl<string>) => {
    const errors: Record<string, any> = {};
    const value = control.value?.trim();

    if (!value && !control.hasError('required')) {
      return null;
    }
    if (!new RegExp(/^\w{8}/).test(value)) {
      errors['passwordLength'] = {
        currentLength: value.length,
        requiredLength: 8,
      };
    }
    if (!new RegExp(/\d{1}/).test(value)) {
      errors['passwordWithoutNumber'] = {
        requiredNumbers: 1,
      };
    }
    if (!new RegExp(/[A-Za-zÀ-ÖØ-öø-ÿ]{1}/).test(value)) {
      errors['passwordWithoutLetter'] = {
        requiredLetters: 1,
      };
    }
    return Object.keys(errors).length ? errors : null;
  };

  static phone: ValidatorFn = (control: AbstractControl<string>) => {
    const value = control.value?.trim();

    if (!value && !control.hasError('required')) {
      return null;
    }
    const regexp = new RegExp(/^\d{9}$/);
    return regexp.test(value) ? null : { phone: value };
  };

  static postalCode: ValidatorFn = (control: AbstractControl<string>) => {
    const value = control.value?.trim();

    if (!value && !control.hasError('required')) {
      return null;
    }
    const regexp = new RegExp(/^\d{5}$/);
    return regexp.test(value) ? null : { postalCode: value };
  };

  static dni: ValidatorFn = (control: AbstractControl<string>) => {
    const value = control.value?.trim();

    if (!value && !control.hasError('required')) {
      return null;
    }
    const regexp = new RegExp(/^\d{7,8}[a-zA-Z]{1}$/);
    return regexp.test(value) ? null : { identityDocument: value };
  };

  static nif: ValidatorFn = (control: AbstractControl<string>) => {
    const value = control.value?.trim();

    if (!value && !control.hasError('required')) {
      return null;
    }
    const regexp1 = new RegExp(
      /^[ABCDEFGHJKLMNPQRSUVWXYZabcdefghjklmnpqrsuvwxyz]{1}\d{7}[a-zA-Z]{1}$/
    );
    const regexp2 = new RegExp(/^\d{7,8}[a-zA-Z]{1}$/);

    return regexp1.test(value) || regexp2.test(value) ? null : { nif: value };
  };

  static hour: ValidatorFn = (control: AbstractControl<string>) => {
    const value = control.value;

    if (!value && !control.hasValidator(Validators.required)) {
      return null;
    }
    const regexp = new RegExp(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/);
    return regexp.test(value) ? null : { hour: value };
  };

  static maxFilesSize = (maxSize: number, formControlName: string): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control instanceof FormArray) {
        let filesSize = 0;
        control.controls.forEach((group: AbstractControl) => {
          const fileControl = group.get(formControlName);
          if (fileControl && fileControl.value) {
            const fileSize = (fileControl.value.length * 3) / 4 / (1024 * 1024);
            filesSize += fileSize;
          }
        });
        if (filesSize > maxSize) {
          return {
            max_file_size: {
              filesSize: filesSize.toFixed(2),
              exceededValue: (filesSize - maxSize).toFixed(2),
              maxSize: maxSize.toFixed(2),
            },
          };
        }
      }
      return null;
    };
  };

  static hourRange: ValidatorFn = (control: AbstractControl<any>) => {
    if (!(control instanceof FormGroup)) {
      throw new Error('Validator "hour range" can only be used in group controls');
    }
    const { startTime, endTime } = control.controls;

    if (!startTime || !endTime) {
      throw new Error('Hour range validator requires "startTime" and "endTime" controls');
    }
    if (!startTime.value && !endTime.value) {
      return null;
    }
    return startTime.valid && startTime.value && endTime.valid && endTime.value
      ? null
      : { hourRange: control.value };
  };

  static iotCode: ValidatorFn = (control: AbstractControl<string>) => {
    const value = control.getRawValue();

    if (!value && !control.hasValidator(Validators.required)) {
      return null;
    }
    const regexp = new RegExp(/(.)+\d{4}/);
    return regexp.test(value) ? null : { iotCode: value };
  };

  static confirmation =
    (passwordName: string, confirmationName: string) => (control: AbstractControl) => {
      if (!(control instanceof FormGroup)) {
        throw new Error('Confirmation validation must be placed in a form group');
      }
      const passwordControl = control.controls[passwordName];

      if (!(passwordControl instanceof FormControl)) {
        throw new Error('Password control not found for confirmation validation');
      }
      const confirmationControl = control.controls[confirmationName];

      if (!(confirmationControl instanceof FormControl)) {
        throw new Error('Confirmation control not found for confirmation validation');
      }
      const password = passwordControl.value;

      if (typeof password !== 'string') {
        throw new Error('Password control type must be a string');
      }
      const confirmation = confirmationControl.value;

      if (typeof confirmation !== 'string') {
        throw new Error('Password control type must be a string');
      }
      return R.equals(password.trim(), confirmation.trim())
        ? null
        : { confirmation: { password, confirmation } };
    };
}
