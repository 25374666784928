import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { TitledPageComponent } from 'src/app/shared/components/titled-page/titled-page.component';
import { ProfileInfoComponent } from './components/profile-info/profile-info.component';
import { ProfilePasswordComponent } from './components/profile-password/profile-password.component';
import { RouterModule } from '@angular/router';
import { PROFILE_ROUTES } from './profile.constants';
import { RequirePermissionDirective } from 'src/app/shared/directives/require-permission/require-permission.directive';
import { Permission } from '../roles/constants/roles.constants';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    ProfileInfoComponent,
    ProfilePasswordComponent,
    MaterialModule,
    RequirePermissionDirective,
    RouterModule,
    TitledPageComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {
  protected readonly routes = PROFILE_ROUTES;
  protected readonly Permission = Permission;
}
