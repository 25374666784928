import { Component , ChangeDetectionStrategy} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitledPageComponent } from 'src/app/shared/components/titled-page/titled-page.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-maintenances',
  standalone: true,
  imports: [CommonModule, TitledPageComponent, RouterOutlet],
  templateUrl: './maintenances.component.html',
  styleUrls: ['./maintenances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenancesComponent {

}
