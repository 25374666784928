import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { authGuard } from '../guards/auth.guard';
import { AuthenticationComponent } from 'src/app/modules/authentication/authentication.component';
import { MaintenancesComponent } from 'src/app/modules/maintenances/maintenances.component';
import { UsersComponent } from 'src/app/modules/users/users.component';
import { permissionGuard } from '../guards/permission.guard';
import { redirectGuard } from '../guards/redirect.guard';
import { initRedirectGuard } from '../guards/init-redirect.guard';
import { Permission } from 'src/app/modules/roles/constants/roles.constants';
import { ProfileComponent } from 'src/app/modules/profile/profile.component';
import { CulturalAssetsComponent } from 'src/app/modules/cultural-assets/cultural-assets.component';
import { AnalyticsComponent } from 'src/app/modules/analytics/analytics.component';
import { NotificationsComponent } from 'src/app/modules/notifications/notifications.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [initRedirectGuard],
    children: [],
  },
  {
    path: 'authentication',
    component: AuthenticationComponent,
    loadChildren: () => import('./authentication.routes').then((m) => m.routes),
  },
  {
    path: 'dashboard',
    component: LayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [redirectGuard],
        children: [],
      },
      {
        path: 'maintenances',
        component: MaintenancesComponent,
        canActivate: [permissionGuard(Permission.ReadMaintenances)],
        loadChildren: () => import('./maintenances.routes').then((m) => m.routes),
      },
      {
        path: 'profile',
        component: ProfileComponent,
        loadChildren: () => import('./profile.routes').then((m) => m.routes),
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [permissionGuard(Permission.ReadUsers)],
        loadChildren: () => import('./users.routes').then((m) => m.routes),
      },
      {
        path: 'cultural-assets',
        component: CulturalAssetsComponent,
        canActivate: [permissionGuard(Permission.ReadCulturalAssets)],
        loadChildren: () => import('./cultural-assets.routes').then((m) => m.routes),
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
        canActivate: [permissionGuard(Permission.ReadMyAnalytics)],
        loadChildren: () => import('./analytics.routes').then((m) => m.routes),
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [permissionGuard(Permission.ReadNotifications)],
        loadChildren: () => import('./notifications.routes').then((m) => m.routes),
      },
    ],
  },
];
