import { Injectable } from '@angular/core';
import { PaginationValues } from './pagination.models';
import { HttpParams } from '@angular/common/http';
import { DateTime } from 'luxon';
import * as R from 'remeda';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  public toHttpParams = (pagination: PaginationValues) => {
    const parameters = new HttpParams({
      fromObject: R.omit(pagination, ['filters']),
    });
    return this.parseFilters(pagination.filters, parameters);
  };

  public parseFilters = (
    filters: Record<string, any>,
    parameters: HttpParams = new HttpParams()
  ) => {
    return R.pipe(
      R.toPairs(filters),
      R.reduce((params, item) => {
        const [key, value] = item;

        switch (typeof value) {
          case 'string':
            return params.set(key, value);
          case 'number':
            return params.set(key, value.toString());
          case 'undefined':
            return params.set(key, '');
          case 'object':
            if (DateTime.isDateTime(value)) {
              return params.set(key, value.toISODate() ?? '');
            }
            if (typeof value?.toString === 'function') {
              return params.set(key, value.toString());
            }
            if (value != null) {
              return params.set(key, String(value));
            }
            return params;
          default:
            return params.set(key, String(value));
        }
      }, parameters)
    );
  };
}
