import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/validators/custom.validators';

export const PROFILE_PASSWORD_FORM = new FormGroup(
  {
    current: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, CustomValidators.password],
    }),
    confirmation: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, CustomValidators.password],
    }),
    email: new FormControl(''),
  },
  { validators: [CustomValidators.confirmation('password', 'confirmation')] }
);
