export enum Role {
  Superadministrator = 'Superadministrador',
  CentralServices = 'Servicios centrales',
  TerritorialServices = 'Servicios territoriales',
  AssetManager = 'Gestor de bienes',
  Maintenance = 'Mantenimiento',
}

export enum Permission {
  ReadMyself = 'READ_MYSELF',
  WriteMyself = 'WRITE_MYSELF',
  ReadMyPreferences = 'READ_MY_PREFERENCES',
  WriteMyPreferences = 'WRITE_MY_PREFERENCES',
  UpdateMyPassword = 'UPDATE_MY_PASSWORD',
  ReadRoles = 'READ_ROLES',
  WriteRoles = 'WRITE_ROLES',
  ReadUsers = 'READ_USERS',
  WriteUsers = 'WRITE_USERS',
  DeleteUsers = 'DELETE_USERS',
  ReadCulturalAssets = 'READ_CULTURAL_ASSETS',
  ReadAnalytics = 'READ_ANALYTICS',
  ReadNotifications = 'READ_NOTIFICATIONS',
  WriteNotifications = 'WRITE_NOTIFICATIONS',
  DeleteNotifications = 'DELETE_NOTIFICATIONS',
  ReadMaintenances = 'READ_MAINTENANCES',
  WriteMaintenances = 'WRITE_MAINTENANCES',
  DeleteMaintenances = 'DELETE_MAINTENANCES',
  ReadSettings = 'READ_SETTINGS',
  WriteAnalytics = 'WRITE_ANALYTICS',
  ReadMyAnalytics = 'READ_MY_ANALYTICS',
  WriteMyAnalytics = 'WRITE_MY_ANALYTICS',
  ReadMyTabs = 'READ_MY_TABS',
  WriteMyTabs = 'WRITE_MY_TABS',
  ModifySensorFilter = 'MODIFY_SENSOR_FILTER',
  ReadPacuDatasheet = 'READ_PACU_DATASHEET',
  ReadPacuInvestments = 'READ_PACU_INVESTMENTS',
  ReadPacuDiagnoses = 'READ_PACU_DIAGNOSES',
}
