import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-authentication',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  animations: [
    trigger('displayContent', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateZ(0) translateY(-10%)' }),
        animate('500ms 250ms ease', style({ opacity: 1, transform: 'translateZ(0)' })),
      ]),
    ]),
  ],
})
export class AuthenticationComponent {}
