<app-titled-page heading="Área privada">
  <h2>Gestionar mi perfil</h2>
  <mat-tab-group
    [dynamicHeight]="true"
    [mat-stretch-tabs]="false"
    animationDuration="0ms"
    color="accent"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label">
          <mat-icon>manage_accounts</mat-icon>
          <span>Mis datos</span>
        </div>
      </ng-template>
      <h3>Gestión de perfil</h3>
      <app-profile-info></app-profile-info>
    </mat-tab>

    <mat-tab *appRequirePermission="Permission.UpdateMyPassword">
      <ng-template mat-tab-label>
        <div class="label">
          <mat-icon>password</mat-icon>
          <span>Contraseña</span>
        </div>
      </ng-template>
      <h3>Cambio de contraseña</h3>
      <app-profile-password></app-profile-password>
    </mat-tab>
  </mat-tab-group>
</app-titled-page>
