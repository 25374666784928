import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VALIDATION_STATUS_ICON, ValidationPanelStatus } from '../validation-panel.constants';
import { MaterialModule } from 'src/app/shared/material/material.module';

@Component({
  selector: 'app-validation-panel-item',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './validation-panel-item.component.html',
  styleUrls: ['./validation-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationPanelItemComponent {
  @Input({ required: true })
  set valid(valid: boolean) {
    const status = valid ? ValidationPanelStatus.Valid : ValidationPanelStatus.Invalid;
    this.icon.set(VALIDATION_STATUS_ICON[status]);
    this.status.set(status);
  }

  protected readonly icon = signal<string>(VALIDATION_STATUS_ICON.invalid);
  protected readonly status = signal<`${ValidationPanelStatus}`>('invalid');
}
