import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-layout-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFooterComponent {
  protected readonly version = environment.version;
  protected readonly today = DateTime.now().toJSDate();
}
