export const environment = {
  api: {
    url: 'https://patrimonio.duonexstaging.es/api',
  },
  pacu: {
    url: 'https://preservicios.jcyl.es/pacu',
    shareAuthUrl: 'http://preservicios.jcyl.es/pacu/entradaKHNP.jsp',
  },
  wms: {
    layers: [
      {
        key: 'default',
        name: 'Plantas con cubiertas',
        data: {
          url: 'https://idecyl.jcyl.es/geoserver/ps/wms',
          params: {
            SERVICE: 'WMS',
            VERSION: '1.3.0',
            FORMAT: 'image/svg+xml',
            LAYERS: 'pacu_cyl_bic_inmu_vw',
            TRANSPARENT: 'true',
            REQUEST: 'GetMap',
            TILED: 'false',
            CONTINUOUSWORLD: 'true',
            STYLES: 'pacu_cyl_bic_inmu_vw_azul',
          },
          serverType: 'geoserver',
          transition: 0,
        },
      },
      {
        key: 'experimental',
        name: 'Plantas interior (experimental)',
        data: {
          url: 'https://idedes.jcyl.es/geoserver/ps/wms',
          params: {
            SERVICE: 'WMS',
            VERSION: '1.3.0',
            FORMAT: 'image/svg+xml',
            LAYERS: 'pacu_cyl_iglesias_planta',
            TRANSPARENT: 'true',
            REQUEST: 'GetMap',
            TILED: 'false',
            CONTINUOUSWORLD: 'true',
            STYLES: 'pacu_cyl_iglesias_planta_default',
          },
          serverType: 'geoserver',
          transition: 0,
        },
      },
    ],
  },
  version: '1.3.0',
};
