import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/validators/custom.validators';

export const PROFILE_INFO_FORM = new FormGroup({
  firstname: new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(256)],
  }),
  lastname: new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(256)],
  }),
  email: new FormControl({
    value: '',
    disabled: true,
  }),
  phone: new FormControl('', {
    validators: [CustomValidators.phone],
  }),
  avatar: new FormControl<string | null>(null),
});
