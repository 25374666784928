import { Injectable } from '@angular/core';
import { StorageKey } from 'src/app/shared/utils/storage/storage.constants';
import { StorageService } from 'src/app/shared/utils/storage/storage.service';
import { LoginResponse } from 'src/app/modules/authentication/models/authentication.models';
import { Permission } from 'src/app/modules/roles/constants/roles.constants';
import * as clone from 'clone';
import * as R from 'remeda';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  private authUser: LoginResponse | null;

  constructor(private readonly storageService: StorageService) {
    const stored = storageService.get(StorageKey.AuthenticatedUser);
    this.authUser = stored ? JSON.parse(stored) : null;
  }

  get = () => {
    return clone(this.authUser);
  };

  getOrFail = () => {
    const user = clone(this.authUser);

    if (!user) {
      throw new Error('Required user does not exists in service');
    }
    return user;
  };

  set = (user: LoginResponse) => {
    const data = JSON.stringify(user);
    this.storageService.set(StorageKey.AuthenticatedUser, data);
    this.authUser = clone(user);
  };

  delete = () => {
    this.authUser = null;
    this.storageService.delete(StorageKey.AuthenticatedUser);
  };

  hasPermission = (permission: Permission) => {
    if (!this.authUser) {
      return false;
    }
    return R.isDefined(R.find(this.authUser.user.permissions, (p) => p === permission));
  };
}
