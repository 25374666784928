import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-titled-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './titled-page.component.html',
  styleUrls: ['./titled-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitledPageComponent {
  @Input()
  heading!: string;
}
