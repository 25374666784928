import { Injectable } from '@angular/core';
import { Subject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileConverterService {
  blobToDataURL = (file: Blob) => {
    const subject = new Subject<string>();
    const fileReader = new FileReader();

    fileReader.addEventListener('load', () => {
      subject.next(fileReader.result as string);
      subject.complete();
    });
    fileReader.readAsDataURL(file);
    return subject.pipe(take(1));
  };

  base64ToPdfFile = (base64: string, filename?: string) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const file = new File([blob], filename ?? 'file.pdf', { type: 'application/pdf' });
    return file;
  };
}
