import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { DateTime } from 'luxon';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { AuthUserService } from '../services/auth-user/auth-user.service';
import { AuthenticationDomainService } from 'src/app/modules/authentication/services/authentication-domain.service';

export const authGuard: CanActivateFn = () => {
  const authUserService = inject(AuthUserService);
  const authenticationDomainService = inject(AuthenticationDomainService);
  const toastService = inject(ToastService);
  const user = authUserService.get();

  if (!user || !user.token) {
    toastService.error('Debes iniciar sesión para acceder a este contenido');
    authenticationDomainService.logOut();
    return false;
  }
  if (DateTime.fromISO(user.exp) < DateTime.utc()) {
    toastService.error('La sesión ha caducado. Inicia sesión de nuevo');
    authenticationDomainService.logOut();
    return false;
  }
  return true;
};
