import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PaginatedData, PaginationValues } from 'src/app/shared/utils/pagination/pagination.models';
import { PaginationService } from 'src/app/shared/utils/pagination/pagination.service';
import {
  UserDetailDTO,
  UserPaginatedDTO,
  UserPasswordUpdateDTO,
  UserProfileUpdateDTO,
  UserRepositoryDTO,
  UserTabCreateDTO,
  UserUpsertDTO,
} from '../models/users.models';
import { TabDetailDTO, TabRepositoryDTO } from '../../tabs/models/tabs.models';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  private getRootRoute = () => `${this.apiService.getRoot()}/users`;
  private getOneRoute = (id: number) => `${this.getRootRoute()}/${id}`;
  private getPasswordRoute = () => `${this.getRootRoute()}/password`;
  private getProfileRoute = () => `${this.getRootRoute()}/profile`;
  private getTabsRoute = () => `${this.getRootRoute()}/tabs`;
  private getTabRoute = (id: number) => `${this.getTabsRoute()}/${id}`;

  constructor(
    private readonly apiService: ApiService,
    private readonly httpClient: HttpClient,
    private readonly paginationService: PaginationService
  ) {}

  public getPaginated = (pagination: PaginationValues) => {
    return this.httpClient.get<PaginatedData<UserPaginatedDTO>>(this.getRootRoute(), {
      params: this.paginationService.toHttpParams(pagination),
    });
  };

  public getOne = (id: number) => {
    return this.httpClient.get<UserDetailDTO>(this.getOneRoute(id));
  };

  public getProfile = () => {
    return this.httpClient.get<UserDetailDTO>(this.getProfileRoute());
  };

  public getTabs = () => {
    return this.httpClient.get<TabDetailDTO[]>(this.getTabsRoute());
  };

  public getTab = (id: number) => {
    return this.httpClient.get<TabDetailDTO>(this.getTabRoute(id));
  };

  public createTab = (data: UserTabCreateDTO) => {
    return this.httpClient.post<TabRepositoryDTO>(this.getTabsRoute(), data);
  };

  public createOne = (user: UserUpsertDTO) => {
    return this.httpClient.post<UserRepositoryDTO>(this.getRootRoute(), user);
  };

  public updateOne = (id: number, user: UserUpsertDTO) => {
    return this.httpClient.put<UserRepositoryDTO>(this.getOneRoute(id), user);
  };

  public updateProfile = (profile: UserProfileUpdateDTO) => {
    return this.httpClient.put<UserDetailDTO>(this.getProfileRoute(), profile);
  };

  public updatePassword = (data: UserPasswordUpdateDTO) => {
    return this.httpClient.put<void>(this.getPasswordRoute(), data);
  };

  public deleteOne = (id: number) => {
    return this.httpClient.delete<void>(this.getOneRoute(id));
  };
}
