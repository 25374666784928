import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PROFILE_PASSWORD_FORM } from '../profile-password.constants';
import { BehaviorSubject, Subject, map, startWith, takeUntil } from 'rxjs';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ValidationPanelModule } from 'src/app/shared/components/validation-panel/validation-panel.module';

@Component({
  selector: 'app-profile-password-requirements',
  standalone: true,
  imports: [CommonModule, MaterialModule, ValidationPanelModule],
  templateUrl: './profile-password-requirements.component.html',
  styleUrls: ['./profile-password-requirements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePasswordRequirementsComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  form!: typeof PROFILE_PASSWORD_FORM;

  protected readonly alive$ = new Subject<void>();
  protected readonly contextsSubject = new BehaviorSubject<TemplateContext[] | null>(null);
  protected readonly contexts$ = this.contextsSubject.asObservable();

  get password() {
    return this.form.controls.password;
  }

  get confirmation() {
    return this.form.controls.confirmation;
  }

  ngOnInit(): void {
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }

  protected watchFormChanges() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.alive$),
        startWith(this.form.getRawValue()),
        map((): TemplateContext[] => [
          {
            error: this.password.hasError('passwordLength') || this.password.hasError('required'),
            text: 'Mínimo 8 caracteres',
          },
          {
            error:
              this.password.hasError('passwordWithoutNumber') || this.password.hasError('required'),
            text: 'Debe contener un dígito',
          },
          {
            error:
              this.password.hasError('passwordWithoutLetter') || this.password.hasError('required'),
            text: 'Debe contener una letra',
          },
          {
            error:
              this.form.hasError('confirmation') ||
              this.password.hasError('required') ||
              this.confirmation.hasError('required'),
            text: 'La repetición debe coincidir',
          },
        ])
      )
      .subscribe((contexts) => this.contextsSubject.next(contexts));
  }
}

interface TemplateContext {
  error: boolean;
  text: string;
}
