import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TitledPageComponent } from 'src/app/shared/components/titled-page/titled-page.component';

@Component({
  selector: 'app-cultural-assets',
  standalone: true,
  imports: [CommonModule, RouterOutlet, TitledPageComponent],
  templateUrl: './cultural-assets.component.html',
  styleUrls: ['./cultural-assets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CulturalAssetsComponent {}
