import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { Subject, filter, map, startWith, takeUntil } from 'rxjs';
import { FORM_ERROR_MESSAGE_MAP } from './form-error.constants';
import pupa from 'pupa';

@Directive({
  selector: '[appFormError]',
  standalone: true,
})
export class FormErrorDirective implements OnInit, OnDestroy {
  @Input({ required: true })
  control!: string;

  private readonly alive$ = new Subject<void>();
  private readonly errorMessages = FORM_ERROR_MESSAGE_MAP;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly formGroupDirective: FormGroupDirective,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const form = this.formGroupDirective.form;

    if (!form) {
      throw new Error('Form error directive requires to be inside form group to work');
    }
    const control = form.controls[this.control];

    if (!control) {
      throw new Error(`Control with name ${this.control} not found in form group`);
    }
    this.trackStatus(control);
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }

  trackStatus = (control: AbstractControl) => {
    control.statusChanges
      .pipe(
        startWith(control.status),
        takeUntil(this.alive$),
        filter((status) => status === 'INVALID'),
        map(() => {
          const error = Object.entries(control.errors ?? {})[0];
          return [error[0], error[1]];
        })
      )
      .subscribe(([error, params]) => this.updateErrorMessage(error, params));
  };

  updateErrorMessage = (error: string, params: Record<string, any>) => {
    const message = this.errorMessages.get(error);

    if (message) {
      const parameters = params instanceof Object ? params : {};
      const interpolated = pupa(message, parameters, { ignoreMissing: true });
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', interpolated);
    }
  };
}
