import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthUserService } from '../services/auth-user/auth-user.service';
import { Permission } from 'src/app/modules/roles/constants/roles.constants';

export const redirectGuard: CanActivateFn = () => {
  const authUserService = inject(AuthUserService);
  const router = inject(Router);

  switch (true) {
    case authUserService.hasPermission(Permission.ReadCulturalAssets):
      return router.parseUrl('/dashboard/cultural-assets');
    case authUserService.hasPermission(Permission.ReadAnalytics):
      return router.parseUrl('/dashboard/analytics');
    case authUserService.hasPermission(Permission.ReadUsers):
      return router.parseUrl('/dashboard/users');
    case authUserService.hasPermission(Permission.ReadMaintenances):
      return router.parseUrl('/dashboard/maintenances');
    case authUserService.hasPermission(Permission.ReadNotifications):
      return router.parseUrl('/dashboard/notifications');
    default:
      return router.parseUrl('/dashboard/profile');
  }
};
