import { Injectable } from '@angular/core';
import { LoginRequest, LoginResponse, PasswordRecoverDTO, SharedLogin } from '../models/authentication.models';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  private getRootRoute = () => `${this.apiService.getRoot()}/auth`;
  private getLogInRoute = () => `${this.getRootRoute()}/log-in`;
  private getRequestRecoveryRoute = () => `${this.getRootRoute()}/request-recovery`;
  private getPasswordRecoverRoute = () => `${this.getRootRoute()}/recover-password`;
  private getSharedAuthRoute = () => `${this.getRootRoute()}/sso`;

  constructor(private readonly apiService: ApiService, private readonly httpClient: HttpClient) {}

  public logIn = (payload: LoginRequest) => {
    return this.httpClient.post<LoginResponse>(this.getLogInRoute(), payload);
  };

  public requestRecovery = (email: string) => {
    return this.httpClient.post<void>(this.getRequestRecoveryRoute(), { email });
  };

  public recoverPassword = (recover: PasswordRecoverDTO) => {
    return this.httpClient.post<void>(this.getPasswordRecoverRoute(), recover);
  };

  public shareAuth = (params: SharedLogin) =>{
    return this.httpClient.post<any>(this.getSharedAuthRoute(),params);

  }

}
