import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthUserService } from '../services/auth-user/auth-user.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { Permission } from 'src/app/modules/roles/constants/roles.constants';

export const permissionGuard =
  (permission: Permission): CanActivateFn =>
  () => {
    const authUserService = inject(AuthUserService);
    const toastService = inject(ToastService);

    if (!authUserService.get()) {
      toastService.error('Es necesario iniciar sesión');
      return false;
    }
    if (!authUserService.hasPermission(permission)) {
      toastService.error('No dispones de suficientes permisos');
      return false;
    }
    return true;
  };
