import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { PROFILE_PASSWORD_FORM } from './profile-password.constants';
import { NgForm, ReactiveFormsModule } from '@angular/forms';
import { FormErrorDirective } from 'src/app/shared/directives/form-error/form-error.directive';
import { first } from 'rxjs';
import { ProfilePasswordRequirementsComponent } from './profile-password-requirements/profile-password-requirements.component';
import { UsersDomainService } from 'src/app/modules/users/services/users-domain.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { AuthUserService } from 'src/app/core/services/auth-user/auth-user.service';
import { AppService } from 'src/app/core/services/app/app.service';

@Component({
  selector: 'app-profile-password',
  standalone: true,
  imports: [
    CommonModule,
    FormErrorDirective,
    MaterialModule,
    ProfilePasswordRequirementsComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './profile-password.component.html',
  styleUrls: ['./profile-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePasswordComponent implements OnInit, OnDestroy {
  @ViewChild('templateForm', { static: true })
  templateForm!: NgForm;

  protected readonly form = PROFILE_PASSWORD_FORM;
  protected readonly loading = signal<boolean>(false);

  constructor(
    private readonly appService: AppService,
    private readonly authUserService: AuthUserService,
    private readonly toastService: ToastService,
    private readonly usersDomainService: UsersDomainService
  ) {}

  ngOnInit(): void {
    this.patchForm();
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  protected patchForm = () => {
    const { user } = this.authUserService.getOrFail();
    this.form.reset({ email: user.email });
  };

  protected submit = () => {
    if (this.form.invalid) {
      return;
    }
    this.loading.set(true);
    const data = this.form.getRawValue();

    this.usersDomainService
      .updatePassword(data)
      .pipe(first())
      .subscribe({
        next: () => {
          const message = `Contraseña actualizada correctamente`;
          this.toastService.success(message);
          this.loading.set(false);
          this.reset();
        },
        error: (error: HttpErrorResponse) => {
          const { message } = error.error;
          this.toastService.error(message);
          this.loading.set(false);
        },
      });
  };

  protected reset = () => {
    this.templateForm.resetForm();
    this.appService.blur();
    this.form.reset();
  };
}
