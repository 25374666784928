import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MESSAGE_ICONS, MessageType } from './message.constants';
import { MaterialModule } from '../../material/material.module';
import * as clone from 'clone';

@Component({
  selector: 'app-message',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
  @Input({ required: true })
  type!: `${MessageType}`;

  @HostBinding('class')
  get classes() {
    return this.type;
  }

  protected readonly icons = clone(MESSAGE_ICONS);
}
