import { inject } from '@angular/core';
import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { AuthUserService } from '../services/auth-user/auth-user.service';
import { AuthenticationDomainService } from 'src/app/modules/authentication/services/authentication-domain.service';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const authUserService = inject(AuthUserService);
  const authenticationDomainService = inject(AuthenticationDomainService);
  const toastService = inject(ToastService);
  const user = authUserService.get();

  if (user && user.token) {
    const req = request.clone({ setHeaders: { Authorization: user.token } });

    return next(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && AUTH_CODES.includes(error.error.code)) {
          toastService.error(error.error.message);
          authenticationDomainService.logOut();
        }
        throw error;
      })
    );
  }
  return next(request);
};

const AUTH_CODES = ['AUTH_TOKEN_EXPIRED', 'AUTH_TOKEN_REQUIRED'];
