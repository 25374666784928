import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { Observable, Subject, filter, map, switchMap, take, takeUntil } from 'rxjs';
import { FileConverterService } from '../../services/file-converter.service';
import { UploadedFile, UploadedFileWithContent } from './../../constants/file.constants';
import { ValidationPanelModule } from '../validation-panel/validation-panel.module';
import { filesize } from 'filesize';
import { ResizeService } from 'src/app/core/services/resize/resize.service';
import * as R from 'remeda';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [CommonModule, MaterialModule, ValidationPanelModule],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit, OnDestroy {
  @Input()
  set avatar(value: string | null) {
    if (!value) {
      return;
    }
    this.content.set(value);
    this.errors.set({ size: false, exts: false });
  }

  @Input()
  editable: boolean = false;

  @Input()
  extensions: string[] = ['jpg', 'jpeg', 'png'];

  @Input()
  maxSizeBytes: number = 250000;

  @Output()
  upload = new EventEmitter<UploadedFileWithContent | null>();

  @ViewChild('wrapper', { static: true })
  wrapper!: ElementRef;

  protected readonly alive$ = new Subject<void>();
  protected readonly inputSubject = new Subject<Event>();
  protected readonly content = signal<string>('');
  protected readonly ext = signal<string>('');
  protected readonly errors = signal<AvatarErrors>({ size: true, exts: true });
  protected readonly maxSize = filesize(this.maxSizeBytes);

  constructor(
    private readonly fileService: FileConverterService,
    private readonly resizeService: ResizeService,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.updateExtensions();
    this.watchWidthChange();
    this.watchInputChange();
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }

  protected updateExtensions = () => {
    const extensions = R.pipe(
      R.map(this.extensions, (extension) => `.${extension}`),
      R.join(', ')
    );
    this.ext.set(extensions);
  };

  protected watchWidthChange = () => {
    this.resizeService.resize$
      .pipe(
        takeUntil(this.alive$),
        map(() => this.wrapper.nativeElement as HTMLElement)
      )
      .subscribe((element) => {
        const height = element.offsetWidth;
        this.renderer.setStyle(element, 'height', `${height}px`);
      });
  };

  protected watchInputChange = () => {
    this.inputSubject
      .pipe(
        takeUntil(this.alive$),
        map((event) => this.filterEvent(event)),
        filter((file): file is NonNullable<File> => R.isDefined(file)),
        map((file) => this.validateFile(file)),
        filter((file): file is NonNullable<UploadedFile> => R.isDefined(file)),
        switchMap((file) => this.readFile(file))
      )
      .subscribe((file) => {
        this.upload.next(file);
        this.content.set(file.content);
      });
  };

  protected filterEvent = (event: Event) => {
    const fileList = (event.target as HTMLInputElement).files;

    if (!fileList || !fileList.length) {
      return;
    }
    return (fileList as FileList)[0];
  };

  protected validateFile = (file: File | null): UploadedFile | null => {
    if (!file) {
      this.errors.set({ size: true, exts: true });
      return null;
    }
    const extension = R.last(file?.name.split('.'));

    if (!extension) {
      this.errors.set({ size: false, exts: true });
      return null;
    }
    if (!R.find(this.extensions, (ext) => ext === extension)) {
      this.errors.set({ size: false, exts: true });
      return null;
    }
    if (file.size > this.maxSizeBytes) {
      this.errors.set({ size: true, exts: false });
      return null;
    }
    this.errors.set({ size: false, exts: false });
    return { file, extension, name: file.name };
  };

  protected readFile = (file: UploadedFile): Observable<UploadedFileWithContent> => {
    return this.fileService.blobToDataURL(file.file).pipe(
      take(1),
      map((content) => ({ ...file, content }))
    );
  };

  protected deleteAvatar = () => {
    this.upload.next(null);
    this.content.set('');
    this.errors.set({ size: true, exts: true });
  };
}

interface AvatarErrors {
  size: boolean;
  exts: boolean;
}
