import { MenuItem } from './layout-menu.models';
import { Permission } from 'src/app/modules/roles/constants/roles.constants';

export const LAYOUT_MENU_ITEMS: MenuItem[] = [
  {
    text: 'Bienes culturales',
    link: ['/dashboard', 'cultural-assets'],
    icon: 'castle',
    permission: Permission.ReadCulturalAssets,
  },

  {
    text: 'Explotación de datos',
    link: ['/dashboard', 'analytics'],
    icon: 'analytics',
    permission: Permission.ReadMyAnalytics,
  },
  {
    text: 'Usuarios',
    link: ['/dashboard', 'users'],
    icon: 'people',
    permission: Permission.ReadUsers,
  },
  {
    text: 'Mantenimiento',
    link: ['/dashboard', 'maintenances'],
    icon: 'build',
    permission: Permission.ReadMaintenances,
  },
  {
    text: 'Notificaciones',
    link: ['/dashboard', 'notifications'],
    icon: 'notifications',
    permission: Permission.ReadNotifications,
  },
];
