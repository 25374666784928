export const PROFILE_ROUTES: { link: string[]; icon: string; text: string }[] = [
  {
    text: 'Mis datos',
    icon: 'manage_accounts',
    link: ['/dashboard', 'profile', 'info'],
  },
  {
    text: 'Contraseña',
    icon: 'password',
    link: ['/dashboard', 'profile', 'password'],
  },
];
