<ul>
  <ng-container *ngFor="let item of menuItems">
    <li *appRequirePermission="item.permission">
      <a class="link" [routerLink]="item.link" routerLinkActive="active">
        <mat-icon>{{ item.icon }}</mat-icon>
        <span>{{ item.text }}</span>
      </a>
    </li>
  </ng-container>
  <mat-divider></mat-divider>
  <a class="duplicate-tab" [href]="url()" target="_blank">
    <mat-icon>open_in_new</mat-icon>
    <span>Duplicar pestaña</span>
  </a>
</ul>
