import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { RouterModule } from '@angular/router';
import { AuthUserService } from '../../services/auth-user/auth-user.service';
import { LoginUser } from 'src/app/modules/authentication/models/authentication.models';
import { AuthenticationDomainService } from 'src/app/modules/authentication/services/authentication-domain.service';
import { UsersDomainService } from 'src/app/modules/users/services/users-domain.service';
import { UserDetailDTO } from 'src/app/modules/users/models/users.models';
import { take } from 'rxjs';

@Component({
  selector: 'app-layout-header',
  standalone: true,
  imports: [CommonModule, MaterialModule, RouterModule],
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHeaderComponent {
  @Output()
  protected toggleMenu = new EventEmitter<void>();

  protected readonly user$ = this.usersDomainService.getProfile().pipe(take(1));

  constructor(
    private readonly authenticationDomainService: AuthenticationDomainService,
    private readonly usersDomainService: UsersDomainService
  ) {}

  logOut = () => {
    this.authenticationDomainService.logOut();
  };
}
