import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { AvatarComponent } from 'src/app/shared/components/avatar/avatar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PROFILE_INFO_FORM } from './profile-info.constants';
import { UsersDomainService } from 'src/app/modules/users/services/users-domain.service';
import { Subject, first, skip, takeUntil } from 'rxjs';
import { FormErrorDirective } from 'src/app/shared/directives/form-error/form-error.directive';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { MessageComponent } from 'src/app/shared/components/message/message.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { UploadedFileWithContent } from 'src/app/shared/constants/file.constants';
import { AppService } from 'src/app/core/services/app/app.service';

@Component({
  selector: 'app-profile-info',
  standalone: true,
  imports: [
    AvatarComponent,
    CommonModule,
    FormErrorDirective,
    MaterialModule,
    MessageComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('dynamicHeightRender', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms 0ms cubic-bezier(.6,0,.4,1)', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProfileInfoComponent implements OnInit, OnDestroy {
  protected readonly form = PROFILE_INFO_FORM;
  protected readonly loading = signal<boolean>(false);
  protected readonly alive$ = new Subject<void>();
  protected readonly notification = signal<boolean>(true);
  protected readonly avatar = signal<string | null>(null);

  constructor(
    private readonly appService: AppService,
    private readonly toastService: ToastService,
    private readonly usersDomainService: UsersDomainService
  ) {}

  ngOnInit(): void {
    this.patchProfile();
    this.watchFormChange();
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
    this.form.reset();
  }

  protected patchProfile = () => {
    this.usersDomainService
      .getProfile()
      .pipe(first())
      .subscribe((profile) => {
        this.form.patchValue(profile);
        this.avatar.set(profile.avatar);
      });
  };

  protected watchFormChange = () => {
    this.form.valueChanges
      .pipe(takeUntil(this.alive$), skip(1))
      .subscribe(() => this.notification.set(false));
  };

  protected updateAvatar = (avatar: UploadedFileWithContent | null) => {
    const control = this.form.controls.avatar;
    control.patchValue(avatar?.content ?? null);
    control.markAsDirty();
  };

  protected submit = () => {
    if (this.form.invalid || this.form.pristine) {
      return;
    }
    this.loading.set(true);
    const data = this.form.getRawValue();
    this.usersDomainService
      .updateProfile(data)
      .pipe(first())
      .subscribe({
        next: () => {
          const message = 'Información actualizada correctamente';
          this.toastService.success(message);
          this.form.markAsPristine();
          this.loading.set(false);
          this.appService.blur();
        },
        error: (error: HttpErrorResponse) => {
          const { message } = error.error;
          this.toastService.error(message);
          this.loading.set(false);
        },
      });
  };
}
