<form [formGroup]="form" (submit)="submit()" #templateForm="ngForm">
  <article class="form">
    <input
      class="hide"
      type="text"
      name="email"
      id="email"
      formControlName="email"
      autocomplete="email"
    />

    <mat-form-field>
      <mat-label>Contraseña actual</mat-label>
      <input
        matInput
        type="password"
        name="current"
        id="current"
        formControlName="current"
        autocomplete="current-password"
      />
      <mat-hint>Obligatorio</mat-hint>
      <mat-error appFormError control="current"></mat-error>
    </mat-form-field>

    <mat-divider />

    <mat-form-field>
      <mat-label>Nueva contraseña</mat-label>
      <input
        matInput
        type="password"
        name="password"
        id="password"
        formControlName="password"
        autocomplete="new-password"
      />
      <mat-hint>Obligatorio</mat-hint>
      <mat-error appFormError control="password"></mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Repetir nueva contraseña</mat-label>
      <input
        matInput
        type="password"
        name="confirmation"
        id="confirmation"
        formControlName="confirmation"
        autocomplete="new-password"
      />
      <mat-hint>Obligatorio</mat-hint>
      <mat-error appFormError control="confirmation"></mat-error>
    </mat-form-field>
  </article>
  <aside class="requirements">
    <app-profile-password-requirements [form]="form" />
  </aside>
  <footer class="actions">
    <mat-divider />
    <button mat-raised-button color="primary" [disabled]="!form.valid || loading()">
      <mat-icon>check</mat-icon>
      <span>Aplicar cambio</span>
    </button>
  </footer>
</form>
