import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { provideRouter, withRouterConfig } from '@angular/router';
import { routes } from './core/routes/app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './core/interceptors/auth.interceptor';
import { Settings } from 'luxon';
import { NgApexchartsModule } from 'ng-apexcharts';
import es from '@angular/common/locales/es';

registerLocaleData(es, 'es-ES');

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(MaterialModule, NgApexchartsModule),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    {
      provide: LOCALE_ID,
      useValue: 'es-ES',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        Settings.defaultLocale = 'es';
      },
    },
  ],
};
