import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthUserService } from '../services/auth-user/auth-user.service';

export const initRedirectGuard: CanActivateFn = (route, state) => {
  const authUserService = inject(AuthUserService);
  const router = inject(Router);
  const user = authUserService.get()?.user;

  const url = user ? '/dashboard' : 'authentication';
  return router.parseUrl(url);
};
