import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReplaySubject, combineLatest, debounceTime, fromEvent, map, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  private readonly _reload$ = new ReplaySubject<void>(1);
  private readonly _window$ = fromEvent(window, 'resize').pipe(
    takeUntilDestroyed(),
    debounceTime(50),
    startWith(window.innerWidth),
    map((event: Event | number) => {
      if (event instanceof Event) {
        const target = event.target as Window;
        return target.innerWidth;
      }
      return event;
    })
  );
  private readonly _resize$ = combineLatest({
    window: this._window$,
    reload: this._reload$,
  }).pipe(
    takeUntilDestroyed(),
    startWith(window.innerWidth),
    map(() => window.innerWidth)
  );

  public get resize$() {
    return this._resize$;
  }

  constructor() {
    this._reload$.next();
  }

  public refresh = () => {
    this._reload$.next();
  };
}
