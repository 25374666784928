<section class="file-drop" #wrapper>
  <ng-container *ngIf="content() as content; else displayUploadMessage">
    <img class="avatar" [src]="content" alt="Avatar de usuario" />
    <section class="actions">
      <button mat-mini-fab type="button" color="primary" (click)="deleteAvatar()">
        <mat-icon>delete</mat-icon>
      </button>
    </section>
  </ng-container>
  <ng-template #displayUploadMessage>
    <section class="upload">
      <input
        type="file"
        name="avatar"
        id="avatar"
        [accept]="ext()"
        (change)="inputSubject.next($event)"
      />
      <mat-icon>add_photo_alternate</mat-icon>
      <p>Haz click para subir tu avatar</p>
    </section>
  </ng-template>
</section>

<app-validation-panel title="Requisitos de la imagen de perfil" *ngIf="errors() as errors">
  <app-validation-panel-item [valid]="!errors.exts">
    Extensiones permitidas: {{ ext() }}
  </app-validation-panel-item>
  <app-validation-panel-item [valid]="!errors.size">
    Tamaño máximo de la imagen: {{ maxSize }}
  </app-validation-panel-item>
</app-validation-panel>
