<header>
  <app-layout-header (toggleMenu)="toggleMenu()" />
</header>
<main>
  <mat-sidenav-container>
    <mat-sidenav [opened]="open()" [mode]="mode()" (openedChange)="refreshResize()">
      <app-layout-menu (closeMenu)="closeMenu()" />
    </mat-sidenav>
    <mat-sidenav-content class="content">
      <router-outlet />
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
<footer>
  <app-layout-footer></app-layout-footer>
</footer>
